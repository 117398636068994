function Skills(props) {
	return(
		<>
			{/* Skills */}
			<section className="sm:min-h-4/5">
				<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-x-4 gap-y-5 py-3">
					{ props.data.map (skill =>(
							<div key={skill.name} className="p-1 h-full w-full rounded-sm" >
                <div className="px-3 py-5 bg-gray-500 hover:bg-gray-900 rounded-md hover:backdrop-blur-2xl bg-opacity-20 hover:bg-opacity-30" >
                  <div className="flex mb-2 items-center justify-between">
                    <div>
                      <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-black bg-gray-200">
                        {skill.name}
                      </span>
                    </div>
                    <div className="text-right">
                      <span className="text-xs font-semibold inline-block text-white">
                        {skill.skill}
                      </span>
                    </div>
                  </div>
                  <div className="overflow-hidden h-2 mt-4 mb-4 text-xs flex rounded bg-gray-300" >
                    <div style={ {width: `${skill.skill}`, background : `var(--linear2)`, borderImageSource: `linear-gradient(to left, #743ad5, #d53a9d)` } } className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center"></div>
                  </div>
  								<p>{skill.abs}</p>
                </div>
							</div>
						))
					}
				</div>
			</section>
		</>
	)
}

export default Skills;